@import "../../../assets/styles/global/constants";
@import "../../../assets/styles/components/extend";

.cart_container {
    background-color: $bg-light-color;
    padding: $padding;
    .cart_space {
        @extend %initialContainerStyle;
        .no_items {
            text-align: center;
            display: flex;
            flex-direction: column;
            width: max-content;
            margin: 10rem auto;
            row-gap: 2rem;
            p {
                font-weight: 700;
            }
        }
        .list_item {
            display: flex;
            flex-direction: column;
            gap: 3rem;
            table {
                thead tr th {
                    font-weight: 600;
                    font-family: "Poppins";
                }
                tbody tr td {
                    span {
                        height: 100px;
                        display: flex;
                        align-items: center;
                    }
                }
                .total {
                    font-weight: 600;
                    font-family: "Poppins";
                }
                p {
                    display: inline;
                    padding-left: 1rem;
                }
                .red {
                    color: red;
                    cursor: pointer;
                }
            }
            .clear_all {
                width: 100%;
                max-width: 300px;
            }
        }
    }
    .message {
        font-family: "Poppins";
        font-weight: 700;
        font-size: 1.4rem;
        &.success {
            color: rgb(41, 162, 47);
        }
        &.error {
            color: red;
        }
    }
    form {
        position: sticky;
        top: 120px;
        display: block;
        width: 100%;
        max-width: 400px;
        margin: auto;
        margin-top: 4rem;
        text-align: left;

        label {
            font-weight: 600;
        }

        input,
        textarea {
            width: 100%;
            margin-bottom: 1.5rem;
            box-sizing: border-box;
            font-size: 1.8rem;
            resize: none;
        }

        button {
            padding: 1rem;
            cursor: pointer;
            width: 100%;
            font-size: 1.8rem;
        }
    }
}
@media screen and (max-width: 768px) {
    .cart_container {
        padding: $paddingMobile;
        h1 {
            font-size: 1.8em;
        }
        table {
            thead tr th {
                font-size: 1.5rem;
            }
            tbody tr td {
                span,
                P {
                    font-size: 1.5rem;
                }
            }
        }
        .cart_space {
            .list_item {
                .container {
                    margin: 0;
                    max-width: none;
                }
            }
        }
    }
}
