@import "./global/cdn";
@import "./global/constants";
@import "./global/normalize";

* {
   margin: 0;
   padding: 0;
   font-size: 2rem;
   font-family: "Lato";
   font-weight: 300;
   color: $tx-dark-color;
   box-sizing: border-box;
}
html,
body {
   width: 100%;
   background-color: $bg-light-color;
   line-height: 1.5;
   font-size: 62.5%;
}
header {
   position: sticky !important;
   top: 0;
   z-index: 11;
}
h1,
h2,
h3 {
   font-family: "Poppins" !important;
   font-weight: 700 !important;
   margin: 0 !important;
}
a {
   text-decoration: none !important;
}
li {
   list-style-type: none;
}
main {
   min-height: 95vh;
   height: auto;
}
.swal2-title h1 {
   font-size: 2.5rem;
}
.navbar-toggler-icon {
   background-image: url("data:image/svg+xml;charset=utf8,<svg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'><path stroke='rgba(255,255,255, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/></svg>") !important;
}
.navbar-toggler {
   border-color: rgba(255, 225, 225, 1) !important;
   &:focus {
      box-shadow: 0 0 10px #fff !important;
   }
}
.swal2-icon-content {
   color: inherit;
}

.no_items {
   text-align: center;
   display: flex;
   flex-direction: column;
   width: max-content;
   margin: 10rem auto;
   row-gap: 2rem;
   p {
       font-weight: 700;
   }
}
