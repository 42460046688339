@import '../../../assets/styles/global/constants';
@import '../../../assets/styles/components/extend';

.contact_container {
    background-color: $bg-light-color;
    padding: $padding;

    .content {
        @extend %initialContainerStyle;
        max-width: 700px;
        text-align: center;

        h1 {
            font-size: 4rem;
            margin: 2.6rem 0 !important;
        }

        form {
            display: block;
            width: 100%;
            max-width: 400px;
            margin: auto;
            margin-top: 4rem;
            text-align: left;

            label {
                font-weight: 600;
            }

            input,
            textarea {
                width: 100%;
                margin-bottom: 1.5rem;
                box-sizing: border-box;
                font-size: 1.8rem;
                resize: none;
            }

            button {
                padding: 1rem;
                cursor: pointer;
                width: 100%;
                font-size: 1.8rem;
            }
        }
    }
}

@media screen and (max-width: 425px) {
    .contact_container {
        padding: $paddingMobile;

        h1 {
            font-size: 2.5rem !important;
        }

        p,
        label,
        input {
            font-size: 1.6rem;
        }
    }
}