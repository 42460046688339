.container-loader {
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   row-gap: 3rem;
   height: auto;
   min-height: 300px;
   .loader2 {
      width: 48px;
      height: 48px;
      border-radius: 50%;
      display: inline-block;
      border-top: 4px solid #ee6983;
      border-right: 4px solid transparent;
      box-sizing: border-box;
      animation: rotation 1s linear infinite;
      &::after {
         content: "";
         box-sizing: border-box;
         position: absolute;
         left: 0;
         top: 0;
         width: 48px;
         height: 48px;
         border-radius: 50%;
         border-bottom: 4px solid #000;
         border-left: 4px solid transparent;
      }
   }
   p {
      font-weight: 700;
      font-size: 2rem;
   }
   @keyframes rotation {
      0% {
         transform: rotate(0deg);
      }
      100% {
         transform: rotate(360deg);
      }
   }
}
