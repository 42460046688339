.btn-dark {
   padding: 0.375rem 0.75rem;
   font-weight: 400;
   line-height: 27px;
   color: #fff;
   background-color: #212529;
   border-radius: 10px;
   border: 1px solid;
   width: 100%;
   cursor: pointer;
   font-size: 1.8rem;
   transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
      box-shadow 0.15s ease-in-out;
   text-align: center;
   &:hover {
      background-color: #424649;
      border-color: #373b3e;
      color: #fff;
   }
   &:disabled {
      opacity: 0.5;
      cursor: no-drop;
      &:hover {
         border-color: inherit;
         background-color: #212529;
      }
   }
}
