.item_count {
   display: flex;
   flex-direction: column;
   row-gap: 3rem;
   .counter {
      display: flex;
      justify-content: center;
      column-gap: 2rem;
      align-items: center;
      font-size: 1.6rem;
      padding: 2rem 0;
      i {
         color: #848e9c;
         cursor: pointer;
         font-size: 1.6rem;
      }
      .no_action {
         cursor: no-drop;
      }
   }
}
