@import "../global/constants";
%initialContainerStyle {
   gap: 4rem 5rem;
   margin: 4rem auto;
   max-width: $max-width;
}
@media screen and (max-width: 425px) {
   %initialContainerStyle {
      margin: 2rem auto;
   }
}
